import React, { useState } from "react";
import Header from "../../components/header";
import Img from "../../assets/eventimg.png";
import { LightBtn } from "../../components/button";
import Event1 from "../../assets/event.png";
import Event2 from "../../assets/event2.png";
import Event3 from "../../assets/events.png";
import Threebox from "../../components/threebox";
import Footer from "../../components/footer";
import { useNavigate } from "react-router-dom";

const Events = () => {
  return (
    <div>
      <Header />
      <Hero />
      <MainSection />
      <Footer />
    </div>
  );
};

const Hero = () => {
  let navigate = useNavigate();
  return (
    <section className="lg:tw-h-screen tw-py-16 tw-w-full tw-bg-main">
      <div className="container">
        <div>
          <img src={Img} alt="" className="" />
        </div>
        <div className="tw-mt-8">
          <h1 className="mainHeader lg:tw-text-center">
            Designing for the Future: Tips and Tricks
          </h1>
          <p className="tw-text-base tw-text-white lg:tw-text-center tw-pt-6 tw-font-medium manrope">
            Hey guys! Are you one of those people who get overwhelmed with the
            countless amount of design trends and techniques out there? Well,
            don't worry, you're not alone! Designing can be daunting, especially
            when you're aiming for a future-proof{" "}
          </p>
          <div className="tw-mt-8 lg:tw-flex tw-justify-center">
            <LightBtn
              text={"Continue reading"}
              onClick={() => navigate("/events/event-details")}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const MainSection = () => {
  const [category, setCategory] = useState(1),
    [list, setList] = useState(1);
  const CatArr = [1, 2, 3, 4, 5];
  return (
    <section className="tw-w-full tw-bg-white tw-py-20">
      <div className="container">
        <div className="tw-grid tw-grid-cols-5 tw-justify-center tw-gap-6">
          {CatArr.map((item) => (
            <button
              onClick={() => setCategory(item)}
              className={`${
                category === item
                  ? "tw-bg-main tw-text-white"
                  : "tw-bg-white tw-border tw-border-main tw-text-main"
              } tw-h-14 tw-rounded-lg manrope tw-text-xl tw-flex tw-gap-1 tw-justify-center tw-items-center tw-font-medium`}
            >
              <span className="tw-hidden lg:tw-block"> Category </span>
              {item}
            </button>
          ))}
        </div>
        <div className="tw-mt-10">
          <EventCat />
        </div>
        <div className="tw-mt-10 tw-mb-6 tw-flex tw-justify-center tw-gap-6">
          {CatArr.map((item) => (
            <button
              onClick={() => setList(item)}
              className={`${
                list === item
                  ? "tw-bg-main tw-text-white"
                  : "tw-bg-white tw-border tw-border-main tw-text-main"
              } tw-h-10 tw-w-10 tw-rounded-full tw-text-xl tw-font-medium manrope`}
            >
              {item}
            </button>
          ))}
        </div>
      </div>
    </section>
  );
};

const EventCat = () => {
  const Arr = [
    {
      image: Event1,
      title:
        "The Definitive Guide to Revenue Operations: What It Is and Why It Matters",
      date: "jan 24",
      category: "1",
    },
    {
      image: Event2,
      title:
        "The Definitive Guide to Revenue Operations: What It Is and Why It Matters",
      date: "jan 24",
      category: "1",
    },
    {
      image: Event3,
      title:
        "The Definitive Guide to Revenue Operations: What It Is and Why It Matters",
      date: "jan 24",
      category: "1",
    },
    {
      image: Event1,
      title:
        "The Definitive Guide to Revenue Operations: What It Is and Why It Matters",
      date: "jan 24",
      category: "1",
    },
    {
      image: Event2,
      title:
        "The Definitive Guide to Revenue Operations: What It Is and Why It Matters",
      date: "jan 24",
      category: "1",
    },
    {
      image: Event3,
      title:
        "The Definitive Guide to Revenue Operations: What It Is and Why It Matters",
      date: "jan 24",
      category: "1",
    },
    {
      image: Event1,
      title:
        "The Definitive Guide to Revenue Operations: What It Is and Why It Matters",
      date: "jan 24",
      category: "1",
    },
    {
      image: Event2,
      title:
        "The Definitive Guide to Revenue Operations: What It Is and Why It Matters",
      date: "jan 24",
      category: "1",
    },
    {
      image: Event3,
      title:
        "The Definitive Guide to Revenue Operations: What It Is and Why It Matters",
      date: "jan 24",
      category: "1",
    },
  ];
  return (
    <div className="">
      <Threebox Arr={Arr} />
    </div>
  );
};
export default Events;
