import React from "react";
import Header from "../../components/header";
import Fb from "../../assets/fb.svg";
import Instagram from "../../assets/instagram (1).svg";
import Linkedin from "../../assets/linkedin.svg";
import Youtube from "../../assets/youtube (1).svg";
import { LightBtn } from "../../components/button";

const ContactUs = () => {
  return (
    <div>
      <Header />
      <MainSection />
    </div>
  );
};
const MainSection = () => {
  const Socials = [Fb, Instagram, Linkedin, Youtube];
  return (
    <section className="ctaBg tw-w-full tw-py-16 lg:tw-py-0 lg:tw-h-screen">
      <div className="container tw-grid lg:tw-grid-cols-2  tw-gap-10">
        <div>
          <h1 className="tw-text-4xl rowdies tw-font-normal tw-text-white tw-leading-[52px]">
            5B Remi Olowude Street,
            <br /> Lekki, Lagos State
          </h1>
          <h2 className="tw-text-2xl tw-pt-8 tw-text-[#C6D0D6] tw-font-normal manrope">
            Monday - Friday: <span className="tw-text-white">9AM - 5PM</span>
          </h2>
          <div className="tw-mt-10 tw-space-y-2">
            <h6 className="tw-text-2xl manrope tw-text-white tw-font-normal">
              +234 568 890 123
            </h6>
            <h6 className="tw-text-2xl manrope tw-text-white tw-font-normal">
              admin@zeno.ng
            </h6>
            <div className="tw-pt-6 tw-flex tw-gap-4">
              {Socials?.map((item) => (
                <img src={item} alt="" className="" />
              ))}
            </div>
          </div>
        </div>
        <div>
          <form action="">
            <div className="tw-grid tw-grid-cols-2 tw-gap-5">
              <input
                type="text"
                placeholder="Name (Optional)"
                className="tw-rounded-xl tw-h-16 tw-text-base tw-font-medium tw-text-white tw-pl-6 placeholder:tw-text-[#CCD2D5] tw-manrope tw-w-full tw-bg-[#043455]"
              />
              <input
                type="text"
                placeholder="Email"
                className="tw-rounded-xl tw-h-16 tw-text-base tw-font-medium tw-text-white tw-pl-6 placeholder:tw-text-[#CCD2D5] tw-manrope tw-w-full tw-bg-[#043455]"
              />
            </div>
            <textarea
              name="message"
              placeholder="Message"
              className="tw-rounded-xl tw-h-72 tw-mt-6 tw-pt-6 tw-pl-6 tw-text-base tw-font-medium placeholder:tw-text-[#CCD2D5] tw-manrope tw-w-full tw-text-white tw-bg-[#043455]"
              id=""
              cols="30"
              rows="10"
            ></textarea>
            <div className="tw-mt-5">
              <LightBtn text={"Submit"} />
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
export default ContactUs;
