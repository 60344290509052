import React, { useState } from "react";
import Header from "../../components/header";
import Img from "../../assets/Blog Details.png";
import { LightBtn, MainPaginate } from "../../components/button";
import Event1 from "../../assets/event.png";
import Event2 from "../../assets/event2.png";
import Event3 from "../../assets/events.png";
import Threebox from "../../components/threebox";
import Footer from "../../components/footer";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const Blog = () => {
	return (
		<div>
			<Header />
			<Hero />
			<MainSection />
			<Footer />
		</div>
	);
};

const Hero = () => {
	const navigate = useNavigate();
	return (
		<section className="lg:tw-h-screen tw-py-16 lg:tw-py-0 tw-w-full tw-bg-main">
			<div className="container tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-gap-10 tw-h-full tw-items-center">
				<div>
					<img src={Img} alt="" className="" />
				</div>
				<div>
					<h1 className="mainHeader ">
						Designing for the Future: Tips and Tricks
					</h1>
					<p className="tw-text-base tw-text-white tw-pt-6 tw-font-medium manrope">
						Hey guys! Are you one of those people who get overwhelmed with the
						countless amount of design trends and techniques out there? Well,
						don't worry, you're not alone! Designing can be daunting, especially
						when you're aiming for a future-proof{" "}
					</p>
					<div className="tw-mt-8">
						<LightBtn
							text={"Continue reading"}
							onClick={() => navigate("/blogs/blog-details")}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

const MainSection = () => {
	let [categoryState, setState] = useState({ docs: [] }),
		{ data } = useSelector(s => s);

	useEffect(() => {
		if (data?.category?.docs?.length > 0) {
			setState(data?.category);
			setCategory(data?.category?.docs?.[0]?._id);
		}
	}, [data?.category]);

	const [category, setCategory] = useState(1),
		[list, setList] = useState(1);
	const CatArr = [1, 2, 3, 4, 5];
	return (
		<section className="tw-w-full tw-bg-white tw-py-20">
			<div className="container">
				<div className="tw-grid tw-grid-cols-5 tw-justify-center tw-gap-6">
					{categoryState?.docs?.map((item, i) => (
						<button
							onClick={() => setCategory(item?._id)}
							className={`${
								category === item?._id
									? "tw-bg-main tw-text-white"
									: "tw-bg-white tw-border tw-border-main tw-text-main"
							} tw-h-14 tw-rounded-lg manrope tw-text-xl tw-flex tw-gap-1 tw-justify-center tw-items-center tw-font-medium`}>
							{/* <span className="tw-hidden lg:tw-block"> Category </span> */}
							{item?.title}
						</button>
					))}
				</div>
				{categoryState?.docs?.length === 0 && (
					<div className="tw-grid tw-grid-cols-5 tw-justify-center tw-gap-6">
						{CatArr.map(item => (
							<button
								onClick={() => setCategory(item)}
								className={`${
									category === item
										? "tw-bg-main tw-text-white"
										: "tw-bg-white tw-border tw-border-main tw-text-main"
								} tw-h-14 tw-rounded-lg manrope tw-text-xl tw-flex tw-gap-1 tw-justify-center tw-items-center tw-font-medium`}>
								<span className="tw-hidden lg:tw-block"> Category </span>
								{item}
							</button>
						))}
					</div>
				)}
				<div className="tw-mt-10">
					<EventCat category={category} />
				</div>
				{categoryState?.docs?.length === 0 && (
					<div className="tw-mt-10 tw-mb-6 tw-flex tw-justify-center tw-gap-6">
						{CatArr.map(item => (
							<button
								onClick={() => setList(item)}
								className={`${
									list === item
										? "tw-bg-main tw-text-white"
										: "tw-bg-white tw-border tw-border-main tw-text-main"
								} tw-h-10 tw-w-10 tw-rounded-full tw-text-xl tw-font-medium manrope`}>
								{item}
							</button>
						))}
					</div>
				)}
			</div>
		</section>
	);
};

const EventCat = ({ category }) => {
	const Arr = [
			{
				image: { url: Event1 },
				title:
					"The Definitive Guide to Revenue Operations: What It Is and Why It Matters",
				createdAt: "Jan 24",
				category: { title: "1", _id: "1" },
			},
			{
				image: { url: Event2 },
				title:
					"The Definitive Guide to Revenue Operations: What It Is and Why It Matters",
				createdAt: "Jan 24",
				category: { title: "1", _id: "1" },
			},
			{
				image: { url: Event3 },
				title:
					"The Definitive Guide to Revenue Operations: What It Is and Why It Matters",
				createdAt: "Jan 24",
				category: { title: "1", _id: "1" },
			},
		],
		[eventState, setState] = useState({ docs: Arr }),
		{ data } = useSelector(s => s);

	useEffect(() => {
		if (data?.event?.docs?.length > 0) {
			if (category) {
				let filterOut = data?.event?.docs?.filter(
					it => it?.category?._id === category
				);
				setState({ ...data?.event, docs: filterOut });
			} else setState(data?.event);
		}
	}, [data?.event, category]);

	// let [page, setPage] = useState(1);

	let [range] = useState(9);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	const currentItems = eventState?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(eventState?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % eventState?.docs?.length;
		setItemOffset(newOffset);
		// setPage(1 + event?.selected);
	};

	return (
		<div className="">
			<Threebox Arr={eventState} currentItems={currentItems} />
			{pageCount > 1 && (
				<MainPaginate pageCount={pageCount} handlePageClick={handlePageClick} />
			)}
		</div>
	);
};
export default Blog;
