import React, { useState } from "react";
import Header from "../../components/header";
import { FiArrowRight } from "react-icons/fi";
import { BlueBtn } from "../../components/button";
import Equal from "../../assets/lucide_equal.svg";
import Equal2 from "../../assets/icon.svg";
import Equal3 from "../../assets/icon2.svg";
import { Cta } from "../policy";
import Footer from "../../components/footer";

const Careers = () => {
  return (
    <div>
      <Header />
      <Hero />
      <MainSection />
      <Offers />
      <Cta />
      <Footer />
    </div>
  );
};
const Hero = () => {
  return (
    <section className="tw-w-full heroBg1 tw-h-screen">
      <div className="container tw-grid lg:tw-grid-cols-2 lg:tw-gap-10 tw-h-full tw-items-center">
        <div>
          <h1 className="lg:tw-text-6xl tw-text-4xl rowdies tw-text-white tw-font-normal lg:tw-leading-[64px]">
            Join Our Team and Shape the Future of Smart Mobility
          </h1>
        </div>
        <div className="lg:tw-relative">
          <div className="">
            <p className="tw-text-base lg:tw-pt-28 tw-text-white tw-font-normal manrope">
              The key to our success lies in our talented and diverse team. We
              are passionate about transforming the world of smart mobility, and
              we are always on the lookout for exceptional individuals to join
              us in our mission.{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
const MainSection = () => {
  const [mouse, setMouse] = useState(null);
  const Arr = [
    {
      title: "FrontEnd Developer",
      type: "remote",
      location: "Houston, Texas",
    },
    {
      title: "FrontEnd Developer",
      type: "remote",
      location: "Houston, Texas",
    },
    {
      title: "FrontEnd Developer",
      type: "remote",
      location: "Houston, Texas",
    },
    {
      title: "FrontEnd Developer",
      type: "remote",
      location: "Houston, Texas",
    },
  ];
  return (
    <section className="tw-w-full careerBg tw-py-20">
      <div className="container">
        <h2 className="tw-font-normal lg:tw-text-4xl tw-text-2xl rowdies tw-text-secondary">
          <span className="tw-text-[#0592F6]">Come take a ride with us - </span>
          Explore Our <br /> Current Openings
        </h2>
        <div className="tw-mt-10 tw-space-y-5">
          {Arr?.map((item, i) => (
            <div
              key={i}
              onMouseEnter={() => setMouse(i)}
              onMouseLeave={() => setMouse(null)}
              className={
                mouse === i
                  ? "tw-h-20 tw-flex tw-justify-between tw-items-center lg:tw-w-1/2 tw-rounded-xl tw-shadow-lg tw-px-6 tw-bg-white"
                  : "tw-bg-white tw-rounded-xl tw-border tw-h-20 tw-flex tw-justify-between tw-items-center lg:tw-w-1/2 tw-px-6  tw-border-[#CCD2D5]"
              }
            >
              <div>
                <h5 className="tw-text-xl manrope tw-capitalize tw-font-medium tw-text-main">
                  {item?.title}
                </h5>
                <span className="tw-twxt-sm manrope tw-capitalize tw-font-normal tw-text-main">
                  {item?.type} in {item?.location}
                </span>
              </div>
              {mouse === i && (
                <div className="tw-flex tw-cursor-pointer tw-items-center tw-gap-2">
                  <h6 className="tw-text-main tw-font-normal rowdies tw-text-base">
                    Apply
                  </h6>

                  <FiArrowRight />
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="tw-mt-10">
          <BlueBtn text={"See More"} />
        </div>
      </div>
    </section>
  );
};
const Offers = () => {
  const Arr = [
    {
      icon: Equal2,
      text: "Get the opportunity to work on technology solutions that will impact the future of mobility across Africa.",
    },
    {
      icon: Equal,
      text: "Collaborate with industry leaders across different teams to execute world-class projects.",
    },
    {
      icon: Equal3,
      text: "Earn what you’re worth, from wherever you are. Join our remote team, and work flexibly from any location.",
    },
    {
      icon: Equal,
      text: "Bring your ideas and your voice, and we'll never shut you down. The best work is done when everyone is heard.",
    },
  ];
  return (
    <section className="tw-py-20 tw-bg-primary tw-w-full">
      <div className="container">
        <h3 className="myHeader">What working at ZENO looks like</h3>
        <div className="tw-mt-10 tw-grid lg:tw-grid-cols-2 tw-gap-16">
          {Arr?.map((item) => (
            <div key={item} className="">
              <img src={item?.icon} alt="" className="" />
              <h5 className="tw-text-secondary tw-pt-7 tw-max-w-sm manrope tw-font-normal tw-text-base">
                {item?.text}
              </h5>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default Careers;
