import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import Right from "../../assets/btnright.svg";
import Left from "../../assets/btnleft.svg";
import Equal from "../../assets/lucide_equal.svg";
import AppImg from "../../assets/app.png";
import Playstore from "../../assets/playstore.svg";
import Appstore from "../../assets/appstore.svg";
import MyBtn, { MainPaginate } from "../../components/button";
import InvestImg from "../../assets/invest.png";
import InfraImg from "../../assets/infrastructure.png";
import Collab1 from "../../assets/lagos.svg";
import Collab2 from "../../assets/collab2.svg";
import Collab3 from "../../assets/collab3.svg";
import Collab4 from "../../assets/collab4.svg";
import Collab5 from "../../assets/collab5.svg";
import Collab6 from "../../assets/collab6.svg";
import Collab7 from "../../assets/collab7.svg";
import Event1 from "../../assets/event.png";
import Event2 from "../../assets/event2.png";
import Event3 from "../../assets/events.png";
import Threebox from "../../components/threebox";
import Footer from "../../components/footer";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const TypeArr = [
	{ name: "Home Page Hero Section", value: "home" },
	{ name: "About Section", value: "about" },
	{ name: "Home Download Section", value: "download" },
];

const Home = () => {
	return (
		<div>
			<Header />
			<Hero />
			<Services />
			<Stats />
			<AppSection />
			<InvestSection />
			{/* <Infrastructure /> */}
			<Collaborators />
			<Events />
			<Footer />
		</div>
	);
};

const Hero = () => {
	const [screen, setScreen] = useState(""),
		[heroState, setState] = useState(null),
		{ data } = useSelector(s => s);

	useEffect(() => {
		if (data?.hero?.docs?.length > 0) {
			setState(data?.hero?.docs?.find(it => it?.type === "home"));
		}
	}, [data?.hero]);

	return (
		<section className="tw-w-full heroBg tw-h-screen tw-bg-main">
			<div className="container tw-flex tw-items-center tw-h-full tw-relative">
				<div data-aos="zoom-in" data-aos-duration="1000">
					<h1 className="tw-text-6xl rowdies tw-font-normal tw-text-white tw-leading-[64px]">
						{heroState?.title || (
							<>
								Welcome to Sustainable <br /> Smart Mobility
							</>
						)}
					</h1>
					<p className="tw-font-medium tw-pt-4 tw-text-xl manrope tw-text-[#F2F4F5]">
						{heroState?.description || (
							<>
								We are familiar with you, we ride with you today and stay the
								course <br /> with you , so together we progress into the future
								of our dreams.
							</>
						)}
					</p>
				</div>
				<div className="tw-absolute tw-w-full tw-items-center tw-bottom-10 tw-flex tw-justify-between">
					<div className="tw-flex tw-gap-3">
						<div
							onClick={() => setScreen("s")}
							className={`${
								screen === "" ? "tw-bg-white" : "tw-bg-[#354857]"
							} tw-h-1 tw-rounded tw-w-9`}></div>
						<div
							className={`${
								screen === "2" ? "tw-bg-white" : "tw-bg-[#354857]"
							} tw-h-1 tw-rounded tw-w-9`}></div>
					</div>
					<div className="md:tw-flex tw-hidden tw-gap-3">
						<img src={Left} alt="" className="" />
						<img src={Right} alt="" className="" />
					</div>
				</div>
			</div>
		</section>
	);
};

const Services = () => {
	const Arr = [
			{
				title: "Cutting-edge mobility technology",
				description:
					"Got a mobility problem? We have a software solution to match your needs. Whatever you need to do with mobility technology, pick software that fits, and get started right away.",
			},
			{
				title: "Data at your fingertips",
				description:
					"Just like mobility technology, data makes the world go round. We provide up-to-date insights and data to help you make better decisions with your mobility solutions. Understand your business better, and make informed decisions to optimise your operations.",
			},
			{
				title: "Mobility asset acquisition",
				description:
					"If you think acquiring mobility assets should be easy, we agree. With Cubera, you can purchase mobility assets with ease, through crowdfunded projects. Monitor your investments, and watch your money grow with 0 stress.",
			},
			{
				title: "Dedicated operations team for you",
				description:
					"World-class projects need a world-class operations team, and we’ve got you! Enjoy a fully dedicated team on your next project, primed to help you bring your objectives to reality.",
			},
		],
		[servicesState, setState] = useState({ docs: Arr }),
		{ data } = useSelector(s => s);

	useEffect(() => {
		if (data?.services?.docs?.length > 0) {
			setState(data?.services);
		}
	}, [data?.services]);

	// let [page, setPage] = useState(1);

	let [range] = useState(4);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	const currentItems = servicesState?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(servicesState?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % servicesState?.docs?.length;
		setItemOffset(newOffset);
		// setPage(1 + event?.selected);
	};

	return (
		<section className="tw-w-full pattern tw-bg-white  tw-py-20">
			<div className="tw-grid container lg:tw-grid-cols-2 tw-gap-10">
				{currentItems?.map((item, i) => (
					<div
						data-aos="fade-up"
						data-aos-duration="1500"
						key={i}
						className="tw-space-y-4">
						<div className="tw-bg-main tw-h-12 tw-w-12 tw-rounded-full tw-flex tw-justify-center tw-items-center">
							<img src={Equal} alt="" className="" />
						</div>
						<h2 className="tw-text-4xl tw-font-normal tw-leading-10 rowdies tw-text-main">
							{item?.title}
						</h2>
						<p className="tw-text-base tw-font-medium tw-leading-6 manrope tw-text-secondary">
							{item?.description}
						</p>
					</div>
				))}
			</div>
			{pageCount > 1 && (
				<MainPaginate pageCount={pageCount} handlePageClick={handlePageClick} />
			)}
		</section>
	);
};

const Stats = () => {
	return (
		<section
			// style={{
			//   paddingTop: "60px !important",
			//   paddingBottom: "60px !important",
			// }}
			className="tw-bg-[#0592F6] lg:tw-h-[600px] tw-py-16 pattern2 tw-w-full">
			{/* <div className="container tw-space-y-6"></div> */}
			<div className="">
				<div className="container tw-flex tw-justify-center">
					<div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-center tw-gap-10 lg:tw-relative">
						<div>
							<div className=" tw-bg-[#47CFF9] tw-h-72 tw-w-72 tw-rounded-full tw-flex tw-items-center tw-justify-center">
								<div>
									<h3 className="tw-text-7xl tw-text-center tw-font-medium manrope tw-text-main">
										20k
									</h3>
									<p className="tw-text-main tw-text-base tw-font-bold manrope tw-text-center">
										Total users (Driver <br /> & riders)
									</p>
								</div>
							</div>
							<div className=" tw-bg-white tw-h-48 tw-w-48 lg:tw-absolute lg:tw-top-48 lg:tw-left-44 tw-rounded-full tw-flex tw-items-center tw-justify-center">
								<div>
									<h3 className="tw-text-7xl tw-text-center tw-font-medium manrope tw-text-main">
										3x
									</h3>
									<p className="tw-text-main tw-text-base tw-font-bold manrope tw-text-center">
										Shared Rides
									</p>
								</div>
							</div>
						</div>
						<div>
							<div className=" tw-bg-[#9C17E3] lg:tw-absolute lg:-tw-right-96 lg:tw-top-48 tw-h-56 tw-w-56 tw-rounded-full tw-flex tw-items-center tw-justify-center">
								<div>
									<h3 className="tw-text-6xl tw-text-center tw-font-medium manrope tw-text-white">
										₦1bn
									</h3>
									<p className="tw-text-white tw-text-base tw-capitalize tw-font-bold manrope tw-text-center">
										mobility asset <br /> investment
									</p>
								</div>
							</div>
							<div className=" tw-bg-[#F94CBA] lg:tw-absolute lg:tw-left-72 tw-h-72 tw-w-72 tw-rounded-full tw-flex tw-items-center tw-justify-center">
								<div>
									<h3 className="tw-text-6xl tw-text-center tw-font-medium manrope tw-text-white">
										36.85%
									</h3>
									<p className="tw-text-white tw-text-base tw-font-bold manrope tw-text-center">
										Earn Carbon credits on <br /> LagosRide
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

const AppSection = () => {
	let [heroState, setState] = useState(null),
		{ data } = useSelector(s => s);

	useEffect(() => {
		if (data?.hero?.docs?.length > 0) {
			setState(data?.hero?.docs?.find(it => it?.type === "download"));
		}
	}, [data?.hero]);

	return (
		<section className="tw-w-full tw-relative tw-bg-white">
			<div className="tw-grid md:tw-grid-cols-2 tw-gap-7 tw-items-center">
				<div
					data-aos="zoom-in-up"
					data-aos-duration="1500"
					className="md:tw-ml-4 lg:tw-ml-44  tw-ml-4">
					<h2 className="myHeader">
						{heroState?.title ||
							`Reduce traffic congestion and your carbon footprint with LagosRide.`}{" "}
					</h2>
					<p className="tw-text-base tw-font-normal tw-text-secondary manrope">
						{heroState?.description}
					</p>
					<div className="tw-flex tw-gap-4 tw-mt-6">
						<img
							src={Appstore}
							alt=""
							className="tw-cursor-pointer"
							onClick={
								heroState?.appStoreURL
									? () =>
											window.open(
												heroState?.appStoreURL,
												"_blank",
												"rel=noopener noreferrer"
											)
									: null
							}
						/>
						<img
							src={Playstore}
							alt=""
							onClick={
								heroState?.googlePlayURL
									? () =>
											window.open(
												heroState?.googlePlayURL,
												"_blank",
												"rel=noopener noreferrer"
											)
									: null
							}
							className="tw-cursor-pointer"
						/>
					</div>
					<div className="tw-mt-10">
						<MyBtn text={"Learn More"} />
					</div>
				</div>
				<div className="">
					<img
						src={heroState?.image?.url || AppImg}
						alt=""
						className="tw-w-full tw-object-cover tw-h-min-3/4"
					/>
				</div>
			</div>
		</section>
	);
};

const InvestSection = () => {
	let Arr = [
			{
				title: "Invest in Mobility Asset",
				description: `
        If we are all assured of trust, the journey through life becomes
						easier.., join the community of investors that trust us with their
						funds`,
				image: {
					url: InvestImg,
				},
			},
			{
				title: `Zeno AQT220. The digital infrastructure for mass mobility anywhere
						you go`,
				description: ``,
				image: {
					url: InfraImg,
				},
			},
		],
		[recentState, setState] = useState({ docs: Arr }),
		{ data } = useSelector(s => s);

	useEffect(() => {
		if (data?.recent?.docs?.length > 0) {
			setState(data?.recent);
		}
	}, [data?.recent]);

	return (
		<>
			{recentState?.docs?.map((item, i) => (
				<section className="tw-w-full tw-py-20 tw-bg-white">
					<div className="container tw-grid md:tw-grid-cols-2 tw-gap-16 tw-items-center">
						<img
							data-aos={"fade-up"}
							data-aos-duration="1500"
							src={item?.image?.url}
							alt=""
							className={i % 2 !== 0 ? "tw-order-2" : ""}
						/>
						<div
							data-aos={i % 2 !== 0 ? "fade-down" : "zoom-in"}
							className={i % 2 !== 0 ? "tw-order-1" : ""}
							data-aos-duration="1500">
							<h2 className="myHeader">{item?.title}</h2>
							<p className="tw-text-base tw-font-normal tw-text-secondary manrope">
								{item?.description}
							</p>
							<div className="tw-mt-10">
								<MyBtn text={"Learn More"} />
							</div>
						</div>
					</div>
				</section>
			))}
		</>
	);
};

// const Infrastructure = () => {
// 	return (
// 		<section className="tw-w-full tw-py-20 tw-bg-white">
// 			<div className="container tw-grid md:tw-grid-cols-2 tw-gap-16 tw-items-center">
// 				<div>
// 					<h3
// 						data-aos="fade-down"
// 						data-aos-duration="1000"
// 						className="myHeader tw-max-w-xl">
// 						Zeno AQT220. The digital infrastructure for mass mobility anywhere
// 						you go
// 					</h3>
// 					<div className="tw-mt-10">
// 						<MyBtn text={"Learn More"} />
// 					</div>
// 				</div>
// 				<div>
// 					<img src={InfraImg} alt="" className="" />
// 				</div>
// 			</div>
// 		</section>
// 	);
// };

const Collaborators = () => {
	const Partners = [
			{
				image: {
					url: Collab1,
				},
			},
			{
				image: {
					url: Collab2,
				},
			},
			{
				image: {
					url: Collab3,
				},
			},
			{
				image: {
					url: Collab4,
				},
			},
			{
				image: {
					url: Collab5,
				},
			},
			{
				image: {
					url: Collab6,
				},
			},
			{
				image: {
					url: Collab7,
				},
			},
		],
		[collaboratorState, setState] = useState({ docs: Partners }),
		{ data } = useSelector(s => s);

	useEffect(() => {
		if (data?.collaborator?.docs?.length > 0) {
			setState(data?.collaborator);
		}
	}, [data?.collaborator]);
	return (
		<section className="tw-w-full tw-bg-[#F2F4F5] tw-py-16">
			<h3 className="myHeader tw-text-center">Our Collaborators</h3>
			<p className="tw-pt-1 tw-font-medium tw-text-center tw-text-base tw-text-secondary manrope tw-capitalize">
				together we are able to do more
			</p>
			<div className="tw-mt-5 tw-flex tw-flex-col lg:tw-flex-row tw-gap-10 tw-justify-center">
				{collaboratorState?.docs?.map((item, i) => (
					<img
						key={i}
						src={item?.image?.url}
						alt=""
						className="tw-h-14 lg:tw-h-28"
					/>
				))}
			</div>
		</section>
	);
};

const Events = () => {
	const navigate = useNavigate();
	const Arr = [
			{
				image: { url: Event1 },
				title:
					"The Definitive Guide to Revenue Operations: What It Is and Why It Matters",
				createdAt: "Jan 24",
				category: { title: "1" },
			},
			{
				image: { url: Event2 },
				title:
					"The Definitive Guide to Revenue Operations: What It Is and Why It Matters",
				createdAt: "Jan 24",
				category: { title: "1" },
			},
			{
				image: { url: Event3 },
				title:
					"The Definitive Guide to Revenue Operations: What It Is and Why It Matters",
				createdAt: "Jan 24",
				category: { title: "1" },
			},
		],
		[eventState, setState] = useState({ docs: Arr }),
		{ data } = useSelector(s => s);

	useEffect(() => {
		if (data?.event?.docs?.length > 0) {
			setState(data?.event);
		}
	}, [data?.event]);

	return (
		<section className="tw-w-full tw-relative tw-py-20 tw-bg-white">
			<div className="container">
				<h3 className="myHeader">Our Events</h3>
				<Threebox
					Arr={eventState}
					currentItem={eventState?.docs?.slice(0, 9)}
				/>
				<div className="tw-mt-10 tw-flex tw-justify-center">
					<MyBtn text={"See other Events"} onClick={() => navigate("/blogs")} />
				</div>
			</div>
		</section>
	);
};
export default Home;
