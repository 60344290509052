import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PageRender from "./PageRender";
import Index from "./screens/home";

const Routers = () => {
	return (
		<>
			<ToastContainer position="bottom-right" />
			<Routes>
				<Route path="/" element={<Index />} />
				<Route path="/:page" element={<PageRender />} />
				<Route path="/:page/:id" element={<PageRender />} />
				<Route path="/:page/:id/:step" element={<PageRender />} />
			</Routes>
		</>
	);
};

export default Routers;
