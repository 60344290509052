import React from "react";
import ReactPaginate from "react-paginate";

export const MainPaginate = ({ handlePageClick, pageCount }) => (
	<div className="tw-flex tw-justify-center tw-items-center tw-py-10">
		<ReactPaginate
			breakLabel="..."
			onPageChange={handlePageClick}
			pageRangeDisplayed={5}
			pageCount={pageCount}
			renderOnZeroPageCount={null}
			pageClassName="tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-text-xl tw-font-medium manrope tw-bg-white tw-border tw-border-main tw-text-main tw-mx-2 tw-cursor-pointer"
			className="tw-flex tw-items-center tw-justify-center"
			previousClassName="tw-hidden"
			nextClassName="tw-hidden"
			activeClassName="tw-bg-[#021C2F] tw-text-white"
		/>
	</div>
);

const MyBtn = ({ text, onClick }) => {
  return (
    <div>
      <button
        onClick={onClick}
        className="tw-bg-main tw-text-xl manrope tw-text-white tw-font-medium tw-h-14 tw-px-5 hover:tw-bg-[#0592F6] tw-rounded-md tw-transition tw-duration-500 tw-ease-in-out tw-transform hover:tw-scale-105"
      >
        {text}
      </button>
    </div>
  );
};

export const LightBtn = ({ text, onClick }) => {
  return (
    <div>
      <button
        onClick={onClick}
        className="tw-bg-white tw-text-xl manrope tw-text-main tw-font-medium tw-h-14 tw-px-5 hover:tw-bg-[#0592F6] hover:tw-text-white tw-rounded-md tw-transition tw-duration-500 tw-ease-in-out tw-transform hover:tw-scale-105"
      >
        {text}
      </button>
    </div>
  );
};

export const BlueBtn = ({ text, onClick }) => {
  return (
    <div>
      <button
        onClick={onClick}
        className="tw-bg-[#62ddfc33] tw-text-base manrope tw-text-main tw-font-medium tw-h-14 tw-px-5 hover:tw-bg-[#0592F6] hover:tw-text-white tw-rounded-md tw-transition tw-duration-500 tw-ease-in-out tw-transform hover:tw-scale-105"
      >
        {text}
      </button>
    </div>
  );
};
export default MyBtn;
