import { createSlice } from "@reduxjs/toolkit";
import { SetAuthToken } from "../Config";
import axios from "axios";
import { toast } from "react-toastify";

export const TOKEN = "ZENO_WEBSITE_LOGIN";

let initialState = {
	user: null,
	category: null,
	collaborator: null,
	product: null,
	recent: null,
	services: null,
	team: null,
	event: null,
	hero: null,
};

export const dataSlice = createSlice({
	name: "data",
	initialState,
	reducers: {
		getCategory: (state, { payload }) => {
			state.category = payload?.data || payload;
		},
		getCollaborator: (state, { payload }) => {
			state.collaborator = payload?.data || payload;
		},
		getProduct: (state, { payload }) => {
			state.product = payload?.data || payload;
		},
		getRecent: (state, { payload }) => {
			state.recent = payload?.data || payload;
		},
		getServices: (state, { payload }) => {
			state.services = payload?.data || payload;
		},
		getTeam: (state, { payload }) => {
			state.team = payload?.data || payload;
		},
		getEvent: (state, { payload }) => {
			state.event = payload?.data || payload;
		},
		getHero: (state, { payload }) => {
			state.hero = payload?.data || payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	getCategory,
	getCollaborator,
	getProduct,
	getRecent,
	getServices,
	getTeam,
	getEvent,
	getHero,
} = dataSlice.actions;

export default dataSlice.reducer;

// GET USER INFO
export const loadUser = () => async dispatch => {
	let token = localStorage.getItem(TOKEN);
	if (token) SetAuthToken(token);
	dispatch(Category());
	dispatch(Collaborator());
	dispatch(Product());
	dispatch(Recent());
	dispatch(Services());
	dispatch(Team());
	dispatch(Event());
	dispatch(Hero());
};

export const Collaborator = () => async dispatch => {
	try {
		let res = await axios.get(`/api/v1/collaborator`);

		dispatch(getCollaborator(res.data));
	} catch (err) {
		if (err) console.log({ error: err.response?.data, err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
	}
};

export const Category = () => async dispatch => {
	try {
		let res = await axios.get(`/api/v1/category`);

		dispatch(getCategory(res.data));
	} catch (err) {
		if (err) console.log({ error: err.response?.data, err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
	}
};

export const Product = () => async dispatch => {
	try {
		let res = await axios.get(`/api/v1/product`);

		dispatch(getProduct(res.data));
	} catch (err) {
		if (err) console.log({ error: err.response?.data, err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
	}
};

export const Recent = () => async dispatch => {
	try {
		let res = await axios.get(`/api/v1/recent`);

		dispatch(getRecent(res.data));
	} catch (err) {
		if (err) console.log({ error: err.response?.data, err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
	}
};

export const Services = () => async dispatch => {
	try {
		let res = await axios.get(`/api/v1/services`);

		dispatch(getServices(res.data));
	} catch (err) {
		if (err) console.log({ error: err.response?.data, err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
	}
};

export const Team = () => async dispatch => {
	try {
		let res = await axios.get(`/api/v1/team`);

		dispatch(getTeam(res.data));
	} catch (err) {
		if (err) console.log({ error: err.response?.data, err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
	}
};
export const Event = () => async dispatch => {
	try {
		let res = await axios.get(`/api/v1/event`);

		dispatch(getEvent(res.data));
	} catch (err) {
		if (err) console.log({ error: err.response?.data, err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
	}
};
export const Hero = () => async dispatch => {
	try {
		let res = await axios.get(`/api/v1/hero`);

		dispatch(getHero(res.data));
	} catch (err) {
		if (err) console.log({ error: err.response?.data, err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
	}
};
