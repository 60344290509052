import React from "react";
import Header from "../../components/header";
import Img from "../../assets/aboutimg.png";
import Team1 from "../../assets/tumi.png";
import Team2 from "../../assets/tumi2.png";
import Team3 from "../../assets/tumi3.png";
import MyBtn, { MainPaginate } from "../../components/button";
import { Cta } from "../policy";
import Footer from "../../components/footer";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const About = () => {
	return (
		<div>
			<Header />
			<Hero />
			<Stat />
			<TeamSection />
			<Cta />
			<Footer />
		</div>
	);
};
const Hero = () => {
	let [heroState, setState] = useState(null),
		{ data } = useSelector(s => s);

	useEffect(() => {
		if (data?.hero?.docs?.length > 0) {
			setState(data?.hero?.docs?.find(it => it?.type === "about"));
		}
	}, [data?.hero]);

	return (
		<section className="tw-w-full">
			<div className="tw-w-full lg:tw-absolute tw-bg-white  lg:tw-h-80 tw-py-10 lg:tw-py-0 tw-rounded-b-3xl">
				<div className="container tw-grid tw-h-full tw-items-center lg:tw-grid-cols-2 tw-gap-14">
					<div>
						<h1 className="tw-text-5xl tw-leading-[64px] tw-text-main tw-font-normal rowdies">
							{heroState?.title ||
								`Empowering Smarter Mobility Solutions for a Connected World`}
						</h1>
					</div>
					<div className="">
						<p className="tw-text-base md:tw-pt-28 tw-font-normal manrope tw-text-main">
							{heroState?.description ||
								`We’re revolutionising mobility with innovative software solutions
							and expertise. We believe that technology can take mobility to the
							next level, and we’re building the software solutions to do that.`}
						</p>
					</div>
				</div>
			</div>
			<img
				src={heroState?.image?.url || Img}
				alt=""
				className="tw-w-full tw-object-cover tw-h-min-3/4"
			/>
		</section>
	);
};
const Stat = () => {
	const Arr = [
		{
			value: "247k",
			text: "Lorem ipsum dolor ",
		},
		{
			value: "247k",
			text: "Lorem ipsum dolor ",
		},
		{
			value: "247k",
			text: "Lorem ipsum dolor ",
		},
		{
			value: "247k",
			text: "Lorem ipsum dolor ",
		},
	];
	return (
		<section className="tw-py-16 tw-w-full ctaBg">
			<div className="container tw-grid tw-h-full tw-items-center lg:tw-grid-cols-2 tw-gap-10">
				<div className="tw-grid tw-grid-cols-2 tw-gap-8">
					{" "}
					{Arr?.map(item => (
						<div data-aos="zoom-in" data-aos-duration="1000" className="">
							<h3 className="tw-text-6xl rowdies tw-font-normal tw-text-white">
								{item.value}
							</h3>
							<p className="tw-text-primary tw-text-base manrope tw-font-normal">
								{item.text}
							</p>
						</div>
					))}
				</div>
				<div className="tw-space-4">
					<p className="tw-text-xl tw-font-medium tw-text-white manrope">
						We are at the forefront of smart mobility innovation, developing
						groundbreaking software products that redefine how people and goods
						move. By investing in ZENO, you gain access to our portfolio of
						solutions that address the evolving needs of the mobility industry.
					</p>
					<p className="tw-text-xl tw-font-medium tw-text-white manrope">
						As a company deeply committed to sustainability, ZENO's solutions
						play a crucial role in reducing carbon emissions, improving
						efficiency, and enhancing the overall mobility experience. By
						investing in us, you contribute to a more sustainable future for
						generations to come.
					</p>
				</div>
			</div>
		</section>
	);
};
const TeamSection = () => {
	const Arr = [
			{
				name: "Tumi Adeyemi",
				image: { url: Team1 },
				title: "Cheif Executive officer",
			},
			{
				name: "Tumi Adeyemi",
				image: { url: Team2 },
				title: "Cheif Executive officer",
			},
			{
				name: "Tumi Adeyemi",
				image: { url: Team2 },
				title: "Cheif Executive officer",
			},
			{
				name: "Tumi Adeyemi",
				image: { url: Team1 },
				title: "Cheif Executive officer",
			},
			{
				name: "Tumi Adeyemi",
				image: { url: Team2 },
				title: "Cheif Executive officer",
			},
			{
				name: "Tumi Adeyemi",
				image: { url: Team3 },
				title: "Cheif Executive officer",
			},
		],
		[teamState, setState] = useState({ docs: Arr }),
		{ data } = useSelector(s => s);

	useEffect(() => {
		if (data?.team?.docs?.length > 0) {
			setState(data?.team);
		}
	}, [data?.team]);

	// let [page, setPage] = useState(1);

	let [range] = useState(9);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	const currentItems = teamState?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(teamState?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % teamState?.docs?.length;
		setItemOffset(newOffset);
		// setPage(1 + event?.selected);
	};

	return (
		<section className="tw-w-full tw-py-20 tw-bg-white">
			<div className="container">
				<div className="tw-w-full tw-grid lg:tw-grid-cols-3 tw-gap-10">
					{currentItems?.map((item, i) => (
						<div
							key={i}
							data-aos="zoom-in"
							data-aos-duration="1000"
							className="">
							<img src={item?.image?.url} alt="" className="" />
							<h6 className="tw-pt-3 tw-text-xl tw-text-main tw-font-semibold manrope">
								{item?.name}
							</h6>
							<p className="tw-pt-1 tw-text-sm tw-font-normal manrope tw-text-main">
								{item?.title}
							</p>
						</div>
					))}
				</div>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
				<div className="tw-mt-10">
					<h2 className="myHeader tw-text-center">Start doing what matters</h2>
					<p className="tw-text-base tw-font-normal tw-text-center tw-text-main manrope">
						Start doing work that moves Africa
						<br />
						We’ve saved you a spot. Join a team that’s defining the future of
						mobility through technology.
					</p>
					<div className="tw-flex tw-justify-center tw-mt-5">
						<MyBtn text={"See Openings"} />
					</div>
				</div>
			</div>
		</section>
	);
};
export default About;
