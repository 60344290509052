import React, { useState } from "react";
import MyBtn from "../button";
import { useNavigate } from "react-router-dom";
import Facebook from "../../assets/facebook.svg";
import Instagram from "../../assets/instagram.svg";
import Linkedlin from "../../assets/linkedlin.svg";
import Youtube from "../../assets/youtube.svg";
import Car from "../../assets/car.svg";
import Carblue from "../../assets/car2.svg";
import { Product } from "../header";

const Footer = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const Links = [
    {
      name: "contact us",
      action: () => navigate("/contact"),
    },
    {
      name: "portfolio",
      action: () => setModal(!modal),
    },
    {
      name: "blog",
      action: () => navigate("/blogs"),
    },
    {
      name: "our policy",
      action: () => navigate("/policy"),
    },
  ];
  const Links2 = [
    {
      name: "events",
      action: () => navigate("/events"),
    },
    {
      name: "careers",
      action: () => navigate("/careers"),
    },
    {
      name: "about us",
      action: () => navigate("/about"),
    },
  ];
  const Socials = [Facebook, Instagram, Linkedlin, Youtube],
    Arr = [
      {
        title: "LagosRide",
        description:
          "Reduce traffic congestion and your carbon footprint  with LagosRide. ",
      },
      {
        title: "CUBERA",
        description:
          "If we are all assured of trust, the journey through life becomes easier...join the community of investors that trust us with their funds",
      },
      {
        title: "ZENO Bus",
        description:
          "If we are all assured of trust, the journey through life becomes easier...join the community of investors that trust us with their funds",
      },
    ];
  return (
    <footer className="tw-w-full tw-relative tw-border-t tw-border-t-[#CCD2D5] tw-bg-white tw-py-14">
      <div className="container tw-grid lg:tw-grid-cols-3 tw-gap-3 tw-space-y-6 md:tw-space-y-0">
        <div>
          <h6 className="tw-text-2xl rowdies tw-font-normal tw-text-main">
            Subscribe to our newsletter
          </h6>
          <div className="tw-relative tw-mt-6 tw-border-none tw-bg-[#F2F4F5] tw-h-20 tw-w-full tw-rounded-lg">
            <input
              type="text"
              placeholder="Email"
              className="tw-h-full tw-w-full tw-border-none focus:tw-border-none tw-pl-6 tw-bg-transparent  tw-text-sm manrope tw-text-main tw-font-medium"
              name=""
              id=""
            />
            <div className="tw-absolute tw-right-6 tw-top-3">
              <MyBtn text={"Subscribe"} />
            </div>
          </div>
          <p className="tw-text-sm manrope tw-text-secondary tw-font-normal tw-pt-4">
            By subscribing, you'll receive exciting updates, industry insights,
            and exclusive offers directly in your inbox.
          </p>
        </div>
        <div>
          <div className="tw-flex tw-w-full lg:tw-justify-center">
            <div className="tw-flex tw-gap-10 tw-items-center">
              <div>
                <h6 className="tw-text-2xl rowdies tw-font-normal tw-text-main">
                  Links
                </h6>
                <div className="tw-mt-4 tw-space-y-3">
                  {Links?.map((item) => (
                    <p
                      onClick={item?.action}
                      className="tw-cursor-pointer tw-text-base tw-capitalize tw-font-medium tw-text-secondary manrope"
                    >
                      {item?.name}
                    </p>
                  ))}
                </div>
              </div>
              <div className="tw-mt-4 tw-space-y-3">
                {Links2?.map((item) => (
                  <p
                    onClick={item?.action}
                    className="tw-cursor-pointer tw-text-base tw-capitalize tw-font-medium tw-text-secondary manrope"
                  >
                    {item?.name}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="tw-w-full tw-flex lg:tw-justify-end">
            <div>
              <h6 className="tw-text-2xl rowdies tw-font-normal tw-text-main">
                Follow us on socials
              </h6>
              <div className="tw-mt-6 tw-flex tw-gap-6">
                {Socials?.map((item) => (
                  <img src={item} alt="" className="" />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal && (
        <div className="tw-bg-white tw-absolute -lg:tw-top-20 tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2 lg:tw-p-6 tw-p-2 tw-w-11/12 tw-mx-autu lg:tw-mx-0 lg:tw-max-w-xl tw-shadow-md tw-rounded-xl">
          <h2 className="tw-text-base manrope tw-text-secondary tw-font-medium tw-uppercase">
            portfolio
          </h2>
          <div className="tw-space-y-">
            {Arr?.map((item) => (
              <Portfolio item={item} hoverImage={Carblue} defaultImage={Car} />
            ))}
          </div>
        </div>
      )}
    </footer>
  );
};
const Portfolio = ({ item, defaultImage, hoverImage }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="tw-flex tw-h-full hover:tw-bg-slate-200 tw-p-3 tw-items-center tw-gap-5"
    >
      {isHovered ? (
        <img src={hoverImage} alt="" className="" />
      ) : (
        <img src={defaultImage} alt="" className="" />
      )}
      <div>
        <h6 className="lg:tw-text-sm tw-text-xs tw-text-main tw-font-bold manrope">
          {item.title}
        </h6>
        <p className="lg:tw-text-sm tw-text-xs tw-text-secondary tw-font-normal manrope">
          {item.description}
        </p>
      </div>
    </div>
  );
};
export default Footer;
