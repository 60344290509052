/* eslint-disable no-undef */
import { createElement } from "react";
import { useParams } from "react-router-dom";

const generatePage = (pageName, folder) => {
	const component = () => require(`./${folder}/${pageName}`).default;
	try {
		return createElement(component());
	} catch (error) {
		// return <ErrorPage />;
	}
};

const PageRender = () => {
	const { page, id, step } = useParams();
	const escape2 = ["blog-details", "event-details"];

	let pageName = "";
	if (step) {
		pageName = `${page}/${id}/${"[id]"}`;
	} else if (id) {
		if (
			(page === "blogs" && escape2.includes(id)) ||
			(page === "events" && escape2.includes(id))
		) {
			pageName = `${page}/${id}`;
		} else {
			pageName = `${page}/${"[id]"}`;
		}
	} else {
		pageName = `${page}`;
	}
	return generatePage(pageName, "screens");
};

export default PageRender;
